@import '../styles/screens';
@import '../styles/dimensions';
@import '../styles/font-sizes';

@include screen-min-width('medium') {
    .page:only-child {
        min-height: $section-image-height + $gap-x-large;
    }
}

@include screen-max-width('medium') {
    .page:only-child {
        min-height: var(--content-height);
    }
}
