@import '../../styles/dimensions';
@import '../../styles/font-sizes';
@import '../../styles/colors';

@each $size in ('medium', 'large', 'xlarge') {
    .#{$size} {
        height: map-get($avatar-sizes, $size);
        width: map-get($avatar-sizes, $size);
    }
}

.border {
    border: #{$border-thick} solid #{palette-var('white')};
    // some of our avatars are round all by themselves, this will keep the border non-ugly
    transform: scale(1.03);
}

.container {
    margin: 0 auto;
    position: relative;
}

.badge {
    bottom: -1px;
    position: absolute;
    right: -15px;
    text-align: center;
}

.medium {
    @include font-size-small;
}

.large {
    @include font-size-large;
}

.xlarge {
    @include font-size-x-large;
}

.avatar {
    background: theme-var('inverse-background');
    border-radius: 50%;
    color: theme-var('inverse-text');
    display: block;
    overflow: hidden;
    position: relative;

    &.has-image {
        background: none;
    }
}

.placeholder {
    background: theme-var('skeleton');
}

.image,
.initials {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.image {
    z-index: 1;
}

.initials {
    align-items: center;
    display: flex;
    font-weight: bold;
    justify-content: center;
}
