@import '../../styles/colors';
@import '../../styles/dimensions';
@import '../../styles/screens';

.breadcrumbs {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.skeleton {
    height: var(--font-size-medium);
}

.item {
    display: inline-flex;
    flex-direction: row;
}

.link {
    display: block;
}

.link:hover,
.link:focus {
    @include hoverme;
    color: var(--step-cyanora);
}

.link:active {
    color: pressme('step-cyanora');
}

.item::after {
    content: '>';
    display: inline-block;
    padding: 0 $gap-x-small;
}

.item:last-child::after {
    content: none;
}

.current {
    font-weight: bold;
}

@include screen-max-width('medium') {
    .breadcrumbs {
        flex-wrap: wrap;
    }
}
