@import '../../styles/dimensions';
@import '../../styles/screens';
@import '../../styles/font-sizes';

.author {
    margin: $gap-small 0 $gap-medium;
}

.byline {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: $icon-size-medium;
    line-height: 1;
}

.avatar {
    margin-right: $gap-small;
}

.skeleton {
    height: $line-height-default - $gap-xx-small;
    margin: $gap-xx-small 0;
}

.name {
    @include font-size-default;
    font-weight: bold;
}

@include screen-min-width(medium) {
    .author {
        margin: $gap-small 0 $gap-large;
    }
}
