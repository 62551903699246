$breakpoints: (
    large: 1000px,
    large-tablet: 900px,
    medium: 680px,
    press-banner-layout-shift: 500px,
    small: 414px,
    modal-button-layout-shift: 375px,
    x-small: 320px,
);

@function breakpoint($type) {
    @return map-get($map: $breakpoints, $key: $type);
}

@mixin screen-width($min, $max) {
    @media screen and (min-width: (breakpoint($min) + 1px)) and (max-width: breakpoint($max)) {
        @content;
    }
}

@mixin screen-min-width($type) {
    @media screen and (min-width: (breakpoint($type) + 1px)) {
        @content;
    }
}

@mixin screen-max-width($type) {
    @media screen and (max-width: breakpoint($type)) {
        @content;
    }
}
