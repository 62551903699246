@import './theme';

@mixin absolute-center {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

@mixin disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

@mixin fixed-center {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

@mixin flex-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin loading {
    animation: shimmer 0.7s linear infinite alternate-reverse;
    background: linear-gradient(
        to right,
        theme-var('skeleton') 0%,
        theme-var('skeleton-shimmer') 50%,
        theme-var('skeleton') 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 200%;

    @keyframes shimmer {
        0% {
            background-position: 0% 0;
        }

        100% {
            background-position: 100% 0;
        }
    }
}

@mixin text-align-variability {
    @each $side in 'center', 'right', 'left' {
        @include screen-min-width('medium') {
            .text-align-large-#{$side} {
                text-align: #{$side};
            }
        }

        @include screen-max-width('medium') {
            .text-align-small-#{$side} {
                text-align: #{$side};
            }
        }
    }
}

@mixin scroll-anchor {
    /**
        Fixes scrolling to fragment on page, because of floating header,
        the scroll position is off by header height, over-scrolling the specified content
    */
    .scroll-anchor {
        scroll-margin-top: var(--header-height);
    }
}

/** Tweak alignment along the main axis. Horizontal for flex-direction=row, vertical for column. */
@mixin justify-variability {
    .justify-space-between {
        justify-content: space-between;
    }

    .justify-start {
        justify-content: flex-start;
    }

    .justify-end {
        justify-content: flex-end;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-stretch {
        justify-content: stretch;
    }
}

/** Tweak alignment along the cross axis. Vertical for flex-direction=row, horizontal for column. */
@mixin align-variability {
    .align-space-between {
        align-items: space-between;
    }

    .align-start {
        align-items: flex-start;
    }

    .align-end {
        align-items: flex-end;
    }

    .align-center {
        align-items: center;
    }

    .align-stretch {
        align-items: stretch;
    }
}

@function map-collect($maps...) {
    $collection: ();
    @each $map in $maps {
        $collection: map-merge($collection, $map);
    }
    @return $collection;
}
