@import '../../styles/dimensions';
@import '../../styles/screens';
@import '../../styles/font-sizes';

.article {
    margin-bottom: $gap-x-large;
}

.heading {
    font-weight: bold;

    h1 {
        margin: 0 0 $gap-large 0;
    }
}

.skeleton {
    height: $line-height-default - $gap-xx-small;
    margin: $gap-xx-small 0;
}

.byline-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: $gap-small;
    justify-content: space-between;
    margin: $gap-small 0 $gap-large;
}

.byline-col {
    flex: 1;
}

.share-col {
    align-items: center;
    display: flex;
    flex: 0 0;
    justify-content: flex-end;
}

.footer-share-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
