@import '../../../styles/dimensions';
@import '../../../styles/colors';
@import '../../../styles/screens';
@import '../../../styles/utils';
@import '../../../styles/theme';

@include scroll-anchor;

@each $key, $value in $interactive {
    $theme: map-get($value, 'theme');

    .background-#{$key} {
        @include set-theme-variables($theme);
        background: var(--#{$key});
        color: text-for-background($key);
    }
}

/** This is a nasty workaround.
 * Imagine you have two sections without backgrounds following eachother.
 * They probably have text there and the sections are used for the h2's.
 * There's going to be a huge not-so-nice gap between them.
 *
 * This hack fixes this issue. If a transparent section is followed by another transparent section,
 * this code cuts in half the padding in between.
 */
.background-transparent + .background-transparent:not(:last-of-type) {
    .box {
        padding-top: 0;
    }
}

.loading {
    min-height: 30vw;
}

@supports (display: grid) {
    .grid {
        justify-items: center;
    }

    @include screen-max-width('medium') {
        .grid {
            display: grid;
            grid-template-areas:
                'small-first'
                'small-second';
            grid-template-columns: 100%;
            grid-template-rows: repeat(2, auto);
            row-gap: $gap-small;
        }
    }

    @include screen-min-width('medium') {
        .grid {
            column-gap: $gap-large;
            display: grid;
            grid-template-areas: 'medium-first medium-second';
            grid-template-columns: auto;
            grid-template-columns: repeat(
                2,
                minmax($section-image-height, 1fr)
            );
        }
    }
}

.pad-horizontal {
    @include screen-min-width('medium') {
        padding: 0 $gap-x-large;
    }

    @include screen-max-width('medium') {
        padding: 0 $gap-medium-large;
    }
}

@supports not (display: grid) {
    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media print {
    .section {
        border-top: 0.01cm solid var(--step-cold-steel);
    }

    .grid {
        display: block;
    }
}
