@import '../../styles/colors';
@import '../../styles/screens';
@import '../../styles/dimensions';

.box {
    position: relative;
}

@include background-color-variability(true);

.with-background-image {
    background-position: center center;
    background-repeat: no-repeat;
    color: var(--step-white);
}

.shrink {
    flex-shrink: 1;
}

.grow {
    flex-grow: 1;
}

@each $direction in ('row', 'row-reverse', 'column', 'column-reverse') {
    .#{$direction} {
        display: flex;
        flex-direction: #{$direction};
    }
}

@each $justify in ('flex-end', 'flex-start', 'space-between', 'space-around') {
    .align-#{$justify} {
        align-items: #{$justify};
    }
    .justify-#{$justify} {
        justify-content: #{$justify};
    }
}

.rounded {
    border-radius: $box-border-radius;
}

.shadow {
    box-shadow: 0 0 $box-border-radius theme-var('box-shadow');
}

@each $side in ('top', 'bottom') {
    .pad-#{$side},
    .pad-vertical {
        padding-#{$side}: $gap-large;

        @include screen-min-width('large-tablet') {
            padding-#{$side}: $gap-x-large;
        }
    }
}

@each $side in ('left', 'right') {
    .pad-#{$side},
    .pad-horizontal {
        padding-#{$side}: $gap-small;

        @include screen-min-width('large-tablet') {
            padding-#{$side}: $gap-x-large;
        }
    }
}

@each $position in ('static', 'sticky', 'fixed', 'absolute', 'relative') {
    .#{$position} {
        position: #{$position};
    }
}
