@import '../../../styles/dimensions';
@import '../../../styles/screens';
@import '../../../styles/font-sizes';

.submit {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.button {
    margin: $gap-medium 0 0;
    min-width: $control-width-medium;
}

.disclaimer {
    margin: $gap-medium 0 0;
    padding: 0;
}

.disclaimer-medium,
.disclaimer-medium a {
    @include font-size-default;
}

.disclaimer-small,
.disclaimer-small a {
    @include font-size-x-small;
    color: var(--step-cold-steel);
}

@include screen-max-width('medium') {
    .button,
    .submit {
        max-width: 100%;
        width: 100%;
    }

    .submit {
        justify-content: center;
    }
}
