@import './dimensions';

@mixin font-size-x-small {
    font-size: var(--font-size-x-small);
    line-height: $line-height-small;
}

@mixin font-size-small {
    font-size: var(--font-size-small);
    line-height: $line-height-small;
}

@mixin font-size-default {
    font-size: var(--font-size-default);
    line-height: $line-height-default;
}

@mixin font-size-medium {
    font-size: var(--font-size-medium);
    line-height: $line-height-medium;
}

@mixin font-size-x-large {
    font-size: var(--font-size-x-large);
    line-height: $line-height-x-large;
}

@mixin font-size-large {
    font-size: var(--font-size-large);
    line-height: $line-height-large;
}

/** text aliases */

@mixin text-large-prompt {
    @include font-size-large;
    font-weight: bold;
}

@mixin text-cta {
    @include font-size-default;
    font-weight: bold;
}

@mixin text-menu-header {
    font-size: 21px;
    line-height: 23px;
}

@mixin text-header-text {
    @include font-size-medium;
    font-weight: bold;
}

@mixin text-desktop-header {
    @include font-size-x-large;
    font-weight: bold;
}

@mixin include-font-size-variability {
    .text-menu-header {
        @include text-menu-header;
    }

    .text-header-text {
        @include text-header-text;
    }

    .text-desktop-header {
        @include text-desktop-header;
    }

    .text-cta {
        @include text-cta;
    }

    .text-large-prompt {
        @include text-large-prompt;
    }

    .text-large-prompt,
    .text-cta,
    .text-menu-header,
    .text-header-text,
    .text-desktop-header {
        &:not(:last-child) {
            display: inline-block;
            margin-bottom: $gap-small;
        }
    }
}
