@import '../../styles/dimensions';
@import '../../styles/screens';
@import '../../styles/font-sizes';

.skeleton {
    height: $section-image-height * 1.5;
}

.container {
    margin: 0 0 $gap-large;
}

/** This hack fixes image crop in Safari. */
.image img {
    height: 100%;
}

@include screen-min-width(medium) {
    .container {
        margin: 0 0 $gap-large;
    }
}
