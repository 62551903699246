@import '../../styles/colors';
@import '../../styles/utils';
@import '../../styles/dimensions';
@import '../../styles/font-sizes';

.badge {
    @include flex-center;
    background: palette-var('vertical-gradient-cyanora-to-violet');
    border: $border-thick solid theme-var('background');
    border-radius: 50%;
    color: theme-var('inverse-text');
    font-weight: bold;
}

.medium {
    $size: $icon-size-medium + $border-thick * 3;
    font-size: var(--font-size-medium);
    height: $size;
    letter-spacing: -0.12px;
    line-height: var(--font-size-small);
    width: $size;
}
